<template>
  <AdhibitionList :enterpriseData="enterpriseData"></AdhibitionList>
</template>

<script>
import AdhibitionList from '@/components/adhibition/adhibitionList'
export default {
  name: 'List',
  components:{
    AdhibitionList
  },
  data() {
    return {
      enterpriseData: {
        enterpriseId: '',
        enterpriseName: this.systemName,
        enterpriseType: 'central'
      }
    }
  },
  computed: {
    systemName() {
      return this.$store.getters.systemName
    },
  },
}
</script>

<style scoped>

</style>
